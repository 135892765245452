<template>
  <v-container fluid class="mb-16">
    <v-card class="mb-5">
      <h1>HISTÓRICO EVENTOS</h1>
      <v-divider></v-divider>
      <v-simple-table id="totalBodas"
        data-cols-width="10,10,13,10,10,15,10,10,10,10,10,10,10,15,15,10,10,10,10,10,10,10,10,10,10,15,15,15,15,15,15,16,16,16,16,16">
        <template v-slot:default>
          <thead>
            <tr>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="6"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);">
                GENERALES
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="6"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                ESPACIOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="4"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                VALORACIÓN
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thick" colspan="3"
                class="text-center" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);">
                UBICACIÓN
              </th>
            </tr>
            <tr>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                AÑO
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                Nº EVENTOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                ADULTOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                NIÑOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                TOTAL
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                MEDIA
              </th>


              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                PISCIS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                HOTEL
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                LALANNE
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                LAUS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                LLOTJA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); border-right: 4px solid var(--v-secondary-base);white-space: nowrap !important;" class="text-center">
                CAT
              </th>
              

              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                NS / NC
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                NEGATIVA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                NEUTRA
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); border-right: 4px solid var(--v-secondary-base);white-space: nowrap !important;" class="text-center">
                POSITIVA
              </th>
              
              
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-l-s="thick"
                style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                MONZON
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin"
                style="border: 1px solid var(--v-secondary-base); white-space: nowrap !important;" class="text-center">
                O.ESP.PROPIOS
              </th>
              <th data-a-h="center" data-a-v="center" data-a-wrap="false" data-b-a-s="thin" data-b-r-s="thick"
                style="border: 1px solid var(--v-secondary-base); border-right: 4px solid var(--v-secondary-base);white-space: nowrap !important;" class="text-center">
                CAT
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in resumen" :key="index">
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.fecha }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.eventos }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.nAdultos }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.nNinos }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ ((item.nAdultos + item.nNinos) || 0) }}</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.avAdultos }}</td>

              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.piscis }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.hotel }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.lalanne }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.laus }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.llotja }}</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.cat }}</td>

              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.nsnc }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.negativa }}</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.neutra }}</td>
              <td style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false"  class="text-center">{{ item.positiva }}</td>

              <td data-b-l-s="thick" style="border: 1px solid var(--v-secondary-base);border-left: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.pntMonzon.toFixed(0) }}%</td>
              <td style="border: 1px solid var(--v-secondary-base)" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.pntBodegas.toFixed(0) }}%</td>
              <td data-b-r-s="thick" style="border: 1px solid var(--v-secondary-base);border-right: 4px solid var(--v-secondary-base);" data-a-h="center" data-a-v="center" data-a-wrap="false" class="text-center">{{ item.pntCat.toFixed(0) }}%</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</v-container></template>
  
<script>
import { parseDate, perColumnFilter } from "@/utils";
import TableToExcel from "@linways/table-to-excel";

export default {
  components: {
    SetObjetivo: () => import("@/components/SetObjetivo.vue"),
    NewAdHoc: () => import("@/components/NewAdHoc.vue"),
    TableFilters: () => import("@/components/TableFilters.vue"),
  },
  props: {
    all: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      resumen: []
    };
  },
  mounted() {
    this.getEventos();
  },
  methods: {
    parseDate,
    async getEventos() {
      this.loading = true;
      
      const resumen = await axios({
        method: "GET",
        url: `${process.env.VUE_APP_API_URL}/listado/eventototal`,
      });

      this.resumen = [...this.resumen,...resumen.data];
      this.loading = false;
    }
  },
};
</script>
<style>.espaciador {
  padding: 5px !important
}</style>